import React, { useState } from 'react';

import classes from './calculator.module.scss';

export default function Calculator() {

    const init = {
        num: null,
        sales: null,
        percentage: null,
        estimated: 0
    }

    const [state, setState] = useState(init);

    const changeState = (e, field) => {
        let myState = { ...state }
        const toNumber = Number(e.target.value.replace(/\D/g, ''))
        const toLocale = toNumber.toLocaleString();
        myState[field] = toLocale
        if (myState[field] === "0") {
            myState[field] = null;
        }

        if (myState.sales !== null && myState.num !== null && myState.percentage !== null) {
            let rev = (Number(myState.sales.replace(/\D/g, '')) / Number(myState.num.replace(/\D/g, ''))) * 0.04 * (Number(myState.percentage.replace(/\D/g, '')) / 100) * (Number(myState.num.replace(/\D/g, '')))
            myState.estimated = rev.toFixed(0);
        } else {
            myState.estimated = 0
        }

        setState(myState);
    }

    return (
        <section className={classes.Calculator}>
            <h2>Find out <b>how much you could be earning</b> with Device Protect</h2>
            <div className={classes.entry}>
                <span>#</span>
                <input type='text' placeholder="500" className={classes.calFirstInput}
                    value={state.num}
                    onChange={(e) => changeState(e, 'num')} />
                <p>Target number of devices sold per year</p>
            </div>
            <div className={classes.entry}>
                <span>$</span>
                <input type='text' placeholder="3,000,000" value={state.sales} onChange={(e) => changeState(e, 'sales')} />
                <p>Target revenue for device sales per year</p>
            </div>
            <div className={classes.entry}>
                <span>%</span>
                <input type='text' placeholder="30" value={state.percentage} onChange={(e) => changeState(e, 'percentage')} />
                <p>Target margin on extended warranty</p>
            </div>

            <h6 className={classes.disclaimer}>
                *for extended warranty - 12-months
            </h6>

            <div className={classes.output}>

                <h3>{`$  ${state.estimated ? parseInt(state.estimated).toLocaleString() : "0"}`}</h3>
                <p className={[classes.disc, classes.revenue].join(" ")}>Estimated revenue share*</p>
            </div>
        </section>
    )
}
