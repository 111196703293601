import React from "react";
import classes from "./highlights.module.scss";
import { Link } from "gatsby";
import Image from 'gatsby-image';

export default function Highlights({ features }) {

  return (
    <section className={classes.highlights}>
      {features.map((feature, index) => {
        return (
          <div className={index % 2 === 0 ? classes.left : classes.right}>
            <div className={classes.highlightsText}>
              <div className={classes.highlightsTitle}
                dangerouslySetInnerHTML={{
                  __html: feature.titleNode.childMarkdownRemark.html,
                }}
              />
              <p>{feature.description}</p>
              <Link to={feature.linkUrl} className={classes.link}>
                See more features
              </Link>
            </div>
            <div className={classes.highlightsImg}>
              <Image className="feature-image" fluid={feature.image.fluid} />
              {index % 2 === 0 ? (
                <>
                  <div className={classes.decoration1Mobile} />
                  <div className={classes.decoration2Mobile} />
                </>
              ) : (
                  <>
                    <div className={classes.decoration3Mobile} />
                    <div className={classes.decoration4Mobile} />
                  </>
                )}
            </div>
            {index % 2 === 0 ? (
                <>
                  <div className={classes.decoration1} />
                  <div className={classes.decoration2} />
                </>
              ) : (
                  <>
                    <div className={classes.decoration3} />
                    <div className={classes.decoration4} />
                  </>
                )}
          </div>
        );
      })}
    </section>
  );
}
