import React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import Hero from "../components/hero/hero";
import Calculator from "../components/calculator/calculator";
import BlogComponent from "../components/blogcomponent/blogcomponent";
import { graphql } from "gatsby";
import IconButtons from "../components/Button/IconButtons"

import classes from "./index.module.scss";
import Highlights from "../components/highlights/highlights";

export default function IndexPage({ data }) {
  const pageData = data.allDatoCmsHomepage.edges[0].node;
  const { features, featureButtonTopText, featureButtonTopUrl, featureButtonBottomText, featureButtonBottomUrl, highlights } = pageData;

  return (
    <Layout pageTitle={"Home"}>
      <Hero data={data} />
      <div className={classes.home_about}>

        {highlights.map(highlight => {
          return (
            <div>
              <img className={classes.dollarimg} src={highlight.highlightImage.url} alt="highlight"/>
              <h2>{highlight.highlightTitle}</h2>
              <p>
                {highlight.highlightDescription}
              </p>
            </div>
          )

        })}
      </div>
      <Calculator />
      <div className={classes.firstbuttons}>
        <Link to={featureButtonTopUrl}>
            <IconButtons>{featureButtonTopText}</IconButtons>
        </Link>
      </div>
      <Highlights features={features} />
      <div className={classes.secondbuttons}>
        <Link to={featureButtonBottomUrl}>
          <IconButtons>{featureButtonBottomText}</IconButtons>
        </Link>
      </div>
      <BlogComponent />
    </Layout>
  );
}

export const query = graphql`
  query {
    allDatoCmsHomepage {
      edges {
        node {
          heroTitleNode {
            childMarkdownRemark {
              html
            }
          }
          heroDescription
          heroButtonText
          videoSource
          videoId
          heroStats {
            text
            value
          }
          heroSliderInfo
          highlights {
            highlightImage {
              url
            }
            highlightTitle
            highlightDescription
          }
          features {
            title
            linkText
            linkUrl
            description
            image {
              fluid(
                maxWidth: 360
                maxHeight: 550
              ) {
                ...GatsbyDatoCmsSizes
              }

            }
            titleNode {
              childMarkdownRemark {
                html
              }
            }

          }
          featureButtonTopText
          featureButtonTopUrl
          featureButtonBottomText
          featureButtonBottomUrl
        }
      }
    }
  }
`;
