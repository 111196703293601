import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import classes from "./carousel.module.scss";


export default function MySlider() {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allDatoCmsHomepage {
        edges {
          node {
            heroSlider {
              fluid(
                maxWidth: 360
                maxHeight: 550
              ) {
                ...GatsbyDatoCmsFluid_noBase64
              }
            }
          }
        }
      }
    }
  `);
  let settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
  };
  const imgdata = data.allDatoCmsHomepage.edges[0].node.heroSlider;
  return (
    <div className={classes.carousel}>
      <Slider {...settings}>
        {imgdata.map((imgurl) => {
          return (
            <div className={classes.image}>
              <Image
                fluid={imgurl.fluid}
                className="gatsby-image-sider"
              />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}
