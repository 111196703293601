import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import classes from "./blogcomponent.module.scss"
import Image from "gatsby-image"
import { navigate } from "gatsby"
export default function BlogComponent({ blogPage }) {
  let data = useStaticQuery(graphql`
    query {
      allDatoCmsBlog(limit: 2, filter: { locale: { eq: "en" } }) {
        edges {
          node {
            title
            url
            authorName
            date(formatString: "LL")
            featuredImage {
              fluid(maxWidth: 320, maxHeight: 420) {
                ...GatsbyDatoCmsSizes
              }
            }
            bodyNode {
              childMarkdownRemark {
                html
                excerpt
              }
            }
          }
        }
      }
    }
  `)

  const blogs = data.allDatoCmsBlog.edges

  return (
    <>
      <section className={classes.reads}>
        <div className={classes.readsHeader}>
          {" "}
          <h2>{blogPage ? "More " : ""}Interesting Reads</h2>{" "}
          <Link className={classes.link2} to="/blog">{`See all Blogs >`}</Link>
        </div>

        <div className={classes.cards}>
          {blogs.map(node => {
            const blog = node.node
            return (
              <div
                className={classes.card}
                onClick={() => {
                  navigate(`/blog/${blog.url}`)
                }}
                onKeyDown={() => {
                  navigate(`/blog/${blog.url}`)
                }}
                role="button"
                tabIndex={0}
              >
                <div className={classes.cardImageContainer}>
                  {" "}
                  <Image
                    fluid={blog.featuredImage.fluid}
                    className={classes.cardImage}
                  />
                </div>

                <div className={classes.cardContent}>
                  <h3>{blog.title}</h3>
                  {blog.bodyNode.childMarkdownRemark && (
                    <p>{blog.bodyNode.childMarkdownRemark.excerpt}</p>
                  )}
                  {/* <p>{blog.bodyNode.childMarkdownRemark.excerpt}</p> */}
                  <Link className={classes.link} to={`/blog/${blog.url}`}>
                    Read More
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <Link className={classes.btmlink} to="/blog">{`See all Blogs >`}</Link>
    </>
  )
}
