import React, { useState } from "react";
import classes from "./hero.module.scss";
import MySlider from "../carousel";
import ModalVideo from "react-modal-video";

import IconButtons from "../Button/IconButtons";
export default function Hero({ data }) {
  const heroData = data.allDatoCmsHomepage.edges[0].node;
  const [isModelOpen, setModelOpen] = useState(false);
  return (
    <div>
      <div className={classes.hero}>
        <div className={classes.heroContainer}>
          <div className={classes.decoration1}></div>
          <div className={classes.decoration2}></div>
          <div className={classes.decoration3}></div>
          <div className={classes.divone}>
            <div className={classes.carousel}>
              <MySlider />
            </div>
          </div>
          <div className={classes.Maintext}>
            <div
              className={classes.main_textdiv}
              dangerouslySetInnerHTML={{
                __html: heroData.heroTitleNode.childMarkdownRemark.html,
              }}
            ></div>
            <p>{heroData.heroDescription}</p>
            {heroData.videoId && (
              <IconButtons
                onClick={() => {
                  setModelOpen(true);
                }}
              >
                {heroData.heroButtonText}
              </IconButtons>
            )}
          </div>
        </div>
      </div>
      {heroData.videoId && (
        <ModalVideo
          channel={heroData.videoSource}
          isOpen={isModelOpen}
          videoId={heroData.videoId}
          onClose={() => setModelOpen(false)}
        />
      )}
      <div className={classes.mobileStats}>
        {heroData.heroStats.map((stat) => {
          return (
            <div>
              <h2>{stat.value}</h2>
              <p className={classes.statsp}>{stat.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
